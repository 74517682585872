.Slider
    width: 100%
    height: 30px
    position: relative
    cursor: pointer
    user-select: none
    border-radius: 8px
    &__Track, &__Cache, &__Current
        width: 100%
        height: 10px
        background-color: rgba(255, 255, 255, 0.2)
        position: absolute
        transform: translateY(-50%)
        top: 50%
        left: 0
        pointer-events: none
        border-radius: 10px
    &__Current
        background-color: rgba(255, 255, 255, 0.8)
    &__Pin
        pointer-events: all
        width: 5px
        // height: 10px
        // border-radius: 50%
        background-color: #ffffff
        position: absolute
        transform: translate(-50%, -50%)
        top: 50%
        right: -5px
        &__Ghost
            width: 2px
            height: 10px
            // border-radius: 50%
            background-color: #ffffff
            position: absolute
            transform: translate(-50%, -50%)
            top: 50%
            left: 10px
            transition-property: height, opacity, left
            opacity: 0
            pointer-events: none
            > div
                position: absolute
                left: 50%
                top: 50%
                transform: translate(-50%, 75%)
                transform-origin: center center
                font-size: 12px
                font-weight: 600
    &.show
        .Slider
            &__Pin
                // height: 20px
                &__Ghost
                    transition-property: height, opacity
                    height: 20px
                    opacity: 0.7
                    > div
                        transform: translate(-50%, 75%)
    @media screen and (max-width: 600px) 
        &.show
            .Slider
                &__Pin
                    // height: 20px
                    &__Ghost
                        transition-property: height, opacity
                        height: 20px
                        opacity: 0.7
                        > div
                            transform: translate(-50%, -200%)