.Top
  position: relative
  width: 100%
  z-index: 0
  margin-top: -4rem
  display: flex
  justify-content: center
  overflow: hidden
  .Release
    height: calc(100vh - 150px)
    display: flex
    align-items: center
    justify-content: center
    img
      &.bg
        height: 100%
        object-fit: cover
        position: absolute
        top: 0
        left: 0
        z-index: -1
        opacity: 0.1
      width: 100%
      object-fit: cover
      z-index: -1
    .Wrapper
      display: flex
      background-color: #00000000
      opacity: 1
      background-image: radial-gradient(#000000 0.75px, transparent 0.75px), radial-gradient(#000000 0.75px, #e5e5f700 0.75px)
      background-size: 6px 6px
      background-position: 0 0,3px 3px
      pointer-events: none
      position: relative
      &::before
        content: ''
        background: linear-gradient(90deg, rgba(#060606,1) 10%, rgba(4,4,4,0) 100%)
        width: 15%
        height: 100%
        position: absolute
        top: 0
        left: 0
      &::after
        content: ''
        background: linear-gradient(270deg, rgba(#060606,1) 10%, rgba(4,4,4,0) 100%)
        width: 15%
        height: 100%
        position: absolute
        top: 0
        right: -1px

  .Links
    display: flex
    position: absolute
    top: 3rem
    right: 3rem
    z-index: 4
    pointer-events: all
    .link
      color: #ffffff
      margin: 0 1rem
      svg
        width: 20px
        height: 20px

  .Release
    .Lettering
      position: absolute
      padding-top: 3rem
      z-index: 3
    .actions
      padding-top: 2rem
      position: absolute
      bottom: 3rem
      button
        background-color: #000
        color: #FFF
        font-family: "Barlow Condensed", sans-serif
        text-transform: uppercase
        font-size: 24px
        border: 0
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1)
        padding: 0.3rem 1rem 0.5rem 1rem
        border-radius: 2rem
        margin-top: 2rem
        cursor: pointer

    .Lettering div
      font-family: "Barlow Condensed", sans-serif
      color: rgba(255, 255, 255, 0.9)
      span
        font-size: 0.7em
        line-height: 1em
      &.artist
        letter-spacing: 1em
        font-size: clamp(8px, 3vw, 30px)
        line-height: 1em
        font-weight: bolder
        padding-left: 1em
      &.title
        letter-spacing: -.04em
        line-height: 1em
        padding-bottom: 0.4em
        font-size: clamp(8px, 18vw, 200px)
        font-weight: bolder
        br
          display: none

      &.lf
        font-size: clamp(8px, 1.8vw, 20px)
        a
          color: #FFF
          text-decoration: none
          position: relative
          &::before
            content: ''
            background-color: #000
            width: 0
            height: calc(100% + 4px)
            position: absolute
            top: -1px
            left: -8px
            z-index: -1
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1)
            border-radius: 8px
            opacity: 0
          &:hover::before
            width: calc(100% + 16px)
            opacity: 1
    .Wrapper video
      height: 100vh
      z-index: -1

@media (max-width: 430px)
  .Top
    .Release
      height: calc(100vh - 50px)
    .Links
      display: none
    .Release
      .Lettering div
        &.artist
          font-size: clamp(8px, 5vw, 30px)
          line-height: 1em
          font-weight: bolder
        &.title
          line-height: 0.7em
          font-size: clamp(8px, 30vw, 200px)
      .actions
        padding-top: 0rem
      .Lettering div
        &.title br
          display: block
        &.lf
          font-size: clamp(8px, 4vw, 20px)
          padding: 0 3rem
