.Links
  display: flex
  align-items: center
  justify-content: center
  flex-wrap: wrap
  .link
    color: #444444
    margin: 0 1.5rem
    font-size: 1rem
    font-weight: bold
    text-decoration: none
    position: relative
    svg
      fill: currentColor
      width: 24px
      height: 24px
      z-index: 1
      display: block
    &::before
      content: ''
      background-color: #000000
      position: absolute
      width: 150%
      height: 150%
      bottom: -25%
      left: -25%
      z-index: -1
      border-radius: 50%
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1)
      opacity: 0
    svg path
      fill: currentColor !important
    &:hover
      color: #bebebe
      &::before
        width: 200%
        height: 200%
        bottom: -50%
        left: -50%
        opacity: 1
