.MoreInfo
  width: 100%
  height: 100%
  .releaseViewContent
    width: 100%
    color: #ffffff
    overflow: hidden
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2)
    position: relative
    white-space: pre-line
    text-align: left
    display: flex
    flex-direction: column
    .wrapper
      font-size: 1.35rem
      letter-spacing: -1px
      font-weight: bold
      padding: 2rem 3rem 20rem
      line-height: 1.2rem
      background-color: rgba(0,0,0, .3)
      > div
        margin-bottom: 4rem
      .title
        opacity: .75
        font-weight: 400
        font-size: 1rem
        letter-spacing: -1px 
    .topper
      display: flex
      align-items: center
      gap: 1.5rem
      padding: 4rem 2rem 2rem
      font-size: 2rem
      font-weight: bold
      letter-spacing: -1px
      > .infos
        display: flex
        flex-direction: column
        justify-content: flex-end
        gap: .5rem
        > .title
          font-size: 1.75rem
          line-height: 1em
          min-height: 3.5rem
          display: flex
          flex-direction: column
          justify-content: flex-end
        > .date
          font-size: .75rem
          letter-spacing: .25rem
          text-transform: uppercase
          font-weight: normal
        > .actions
          display: flex
          align-items: center
          gap: 0.5rem
          svg
            display: block
            width: 32px
            height: 32px
            border-radius: 50%
            box-shadow: 2px 2px 10px rgba(0,0,0,0.2)
            cursor: pointer
            &.play
              width: 40px
              height: 40px
              background: grey
              > circle
                fill: none
                stroke: rgba(yellow, 0.9)
                stroke-linecap: round
                stroke-dashoffset: 0
                stroke-dasharray: 93
                transform-origin: center
                animation: rotate 6s infinite linear
                stroke-width: 2
              @keyframes rotate
                0%
                  transform: rotate(0deg)
                100%
                  transform: rotate(360deg) 
              &.playing
                background: orange

            &.download
              fill: none
              circle
                stroke: grey
                stroke-width: 2
              path
                fill: grey
            &.add
              fill: none
              circle
                stroke: grey
                stroke-width: 2
              path
                stroke: grey
                stroke-width: 2
            &.video
              fill: grey
              border-radius: 0%
              &.not
                pointer-events: none
                opacity: .3

      img
        width: 130px
        border-radius: 0.25rem
        box-shadow: 2px 2px 40px rgba(0,0,0,1), 0 0 0 2px rgba(255, 255, 255, 0.1)
    .tracks
      padding: 0rem 2rem 2rem
      > .track
        border-top: 1px solid rgba(255,255,255, 0.1)
        font-size: 0.85rem
        padding: 0.5rem 0
        display: flex
        align-items: center
        justify-content: space-between
        > .infos
          display: flex
          align-items: center
          > .number
            text-align: center
            width: 30px
            font-size: 0.825rem
            opacity: 0.7
          > .name
            align-self: flex-start
        > .controls
          display: flex
          align-items: center
          gap: 0.5rem
          align-self: flex-end
          svg
            display: block
            width: 24px
            height: 24px
            box-shadow: 2px 2px 10px rgba(0,0,0,0.2)
            cursor: pointer
            &.play
              border-radius: 50%
              background: grey
              > circle
                fill: none
                stroke: rgba(yellow, 0.9)
                stroke-linecap: round
                stroke-dashoffset: 0
                stroke-dasharray: 93
                transform-origin: center
                animation: rotate 6s infinite linear
                stroke-width: 2
              @keyframes rotate
                0%
                  transform: rotate(0deg)
                100%
                  transform: rotate(360deg) 
              &.playing
                background: orange

            &.download
              fill: none
              circle
                stroke: grey
                stroke-width: 2
              path
                fill: grey
            &.add
              fill: none
              circle
                stroke: grey
                stroke-width: 2
              path
                stroke: grey
                stroke-width: 2
            &.video
              fill: grey
              &.not
                pointer-events: none
                opacity: .3

@media (max-width: 600px)
  .MoreInfo
    .releaseViewContent
      width: 100%
      overflow: hidden
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2)

      img
        width: 100%
      .wrapper
        padding: 2rem 3rem 10rem