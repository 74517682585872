@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600;700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;700&display=swap')

body, html, #root
  background-color: #040404
  margin: 0
  font-family: 'Poppins'
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  user-select: none
  width: 100%
  height: 100%
  overflow: hidden

*
  box-sizing: border-box

.divider
  height: 1px
  width: calc(100% - 4rem)
  background-color: #3d3d3d73
  margin: 2rem auto
  z-index: 1

.ellipsis
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

button
  font-family: 'Poppins'
  cursor: pointer

.button
  border: 2px solid #000000
  background-color: #000000
  padding: 0.25rem 0.75rem
  border-radius: 0.25rem
  cursor: pointer
  color: #ffffff
  text-decoration: none
  text-transform: uppercase
  font-weight: bold
  &:hover
    border: 2px solid #000000
    background-color: #000000
    color: #ffffff
    box-shadow: 0 0 0 2px white, 0 0 0 4px black

.transition
  transition: all 0.1s cubic-bezier(0, 0.55, 0.45, 1)
  &::after, &::before
    transition: all 0.1s cubic-bezier(0, 0.55, 0.45, 1)

.scrollable
  height: 100%
  overflow: auto
  -webkit-overflow-scrolling: touch
  &::-webkit-scrollbar
    width: 8px
    height: 8px
    background-color: rgba(3,3,3, 1)
  &::-webkit-scrollbar-thumb
    width: 10px
    height: 10px
    background-color: rgba(35, 35, 35, 1)
    margin-right: 10px
  &::-webkit-scrollbar-corner, &::-webkit-resizer
    background-color: rgba(3,3,3, 1)
@media (max-width: 400px)
  .scrollable
    overflow: auto
    height: 100%
    -webkit-overflow-scrolling: touch
    &::-webkit-scrollbar
      width: 0
      height: 0