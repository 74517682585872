.PlayButton
    cursor: pointer
    width: 2rem
    height: 2rem
    > polygon
        fill: #ffffff
    &:nth-child(2)
        width: 4rem
        height: 4rem
    &.disabled
        cursor: default
        opacity: .4
    &.filled
        display: block
        backdrop-filter: blur(20px)
        background: rgba(241, 241, 241, 0.1)
        border-radius: 50%
        box-shadow: 2px 2px 10px rgba(0,0,0,0.2)
        cursor: pointer
        > polygon
            fill: rgba(255,255,255,.4)        
        > circle
            visibility: hidden
            fill: none
            stroke: rgba(yellow, 0.9)
            stroke-linecap: round
            stroke-dashoffset: 0
            stroke-dasharray: 93
            transform-origin: center
            stroke-width: 2
    &.filled.tracked
        background-color: rgba(orange, .8)
        > polygon
            fill: black
        > circle
            animation: rotate 6s infinite linear
            animation-play-state: paused
            visibility: visible
            stroke: black  
    &.filled.tracked.playing
        background-color: rgba(orange, .8)
        > polygon
            fill: black
        > circle
            animation-play-state: running
    @keyframes rotate
        0%
        transform: rotate(0deg)
        100%
        transform: rotate(360deg)   

.AudioPlayer
    width: 340px
    overflow: hidden
    max-height: 700px
    min-height: 72px
    background-color: rgba(#151515, 0.6)
    color: #ffffff
    backdrop-filter: blur(10px)
    user-select: none
    border-radius: 16px
    box-shadow: inset -10px -10px 100px rgba(#000000, 0.2), inset 0 0 0 1px rgba(#949494, 0.1)
    position: absolute
    right: 2rem
    bottom: 2rem
    z-index: 6
    display: flex
    flex-direction: column
    &__Top
        display: flex
        flex-direction: row
        justify-content: center
        padding-bottom: 8px
        padding: 1.5rem 1.5rem 0
        > svg
            display: none
            width: 4rem
            height: 3rem
    &__Cover
        cursor: pointer
        > img
            width: 48px
            border-radius: 2px
            box-shadow: 2px 2px 10px rgba(#000, 0.5), 0 0 0 1px rgba(#adadad, 0.2)
    &__Switch
        cursor: pointer
        svg
            height: 32px
            width: 32px
            stroke: white
            stroke-width: 2
    &__SwitchMobile
        cursor: pointer
        svg
            height: 32px
            width: 32px
            stroke: white
            stroke-width: 2
    &__Controls
        display: flex
        justify-content: space-around
        align-items: center
        padding-bottom: 0.5rem
        svg
            > polygon
                fill: #ffffff
            cursor: pointer
            width: 2rem
            height: 2rem
            &:nth-child(2)
                width: 4rem
                height: 4rem
            &.disabled
                cursor: default
                opacity: .4
    &__ControlsMobile
        display: none        
    &__ShortControls
        display: none   
        position: absolute
        top: 0.5rem
        right: 0.5rem
        height: 4rem
        min-width: 3rem
        > svg
            width: 4rem
            height: 3rem
    &__Title
        display: flex
        flex-direction: column
        padding-left: 16px
        align-items: flex-start
        overflow: hidden
        width: 100%
    &__Name
        font-size: 16px
        font-weight: 700
        width: 100%
        text-align: left
    &__Description       
        font-size: 12px
        width: 100%
        text-align: left
    &__Time
        padding: 0 1.5rem
        font-size: 12px
        font-weight: 600
        display: flex
        justify-content: space-between
        align-items: center
        gap: 16px
        > span
            width: 40px
            text-align: center
    &__List
        height: 0
        overflow: hidden
        > div
            font-size: 12px
            font-weight: 600
            display: flex
            flex-direction: column
            > div
                min-height: 0
                border-top: 1px solid rgba(white, 0.1)
                border-radius: 1rem 1rem 0 0 
                padding: 0 1rem 
                > div
                    padding: 0.5rem 0
                    display: flex
                    justify-content: space-between
                    cursor: pointer
                    &.playing
                        color: orange
                    > div
                        display: flex
                        justify-content: space-between
                        gap: 1rem
                        svg
                            stroke: white
                            stroke-width: 2
                            width: 0.5rem
                > .list_track
                    border-bottom: 1px solid rgba(white, 0.1)
        // &.opened
        //     > div
        //         height: 100%
        //         > div

    @media screen and (max-width: 600px) 
        width: calc(100%)
        min-height: 80px
        max-height: 100vh
        bottom: 0rem
        border-radius: 0
        left: 0rem
        padding: 0
        transform: none
        border-top: 1px solid rgba(white, 0.1)
        &__Switch, &__Controls
            display: none
        &__Top
            padding: 1rem
            margin-bottom: -22px
            > svg
                display: block
        &__ShortControls
            display: flex 
            align-items: center
        &__Time
            padding: 0 1rem
            position: relative
            > .Slider
                width: calc(100% - 2rem)
                top: 0
                position: absolute
            > span
                margin-top: 2rem
            &.closed
                > span
                    display: none
        &__Title
            justify-content: center
        &__Name
            font-size: 14px
            font-weight: 700
            width: 100%
            text-align: left
        &__Description       
            font-size: 10px
            width: 100%
            text-align: left
            margin-bottom: 10px
        &__List
            > div
                font-size: 14px
                font-weight: 600
                > div
                    padding: 0 1.5rem
                    > div
                        padding: 0.75rem 0
        &__ControlsMobile
            display: flex
            justify-content: space-around
            align-items: center
            width: 80%
            margin: 0 auto
            margin-bottom: 1rem
            svg
                > polygon
                    fill: #ffffff
                cursor: pointer
                width: 2rem
                height: 2rem
                &:nth-child(2)
                    width: 4rem
                    height: 4rem
                &.disabled
                    cursor: default
                    opacity: .4    