.Header
  position: sticky
  top: 0
  left: 0
  width: calc(100%)
  height: 4rem
  z-index: 2
  padding: 1rem 2rem
  display: flex
  justify-content: flex-start
  align-items: center
  pointer-events: none
  .title
    color: #ffffff40
    font-size: 1rem
    font-weight: 600
    text-transform: uppercase
  .divider
    position: absolute
    bottom: 0
    left: 0
    height: 2px
    margin: 0
    width: calc(100%)
    max-width: 100%
    background-color: #00000073
  svg
    fill: #FFF
    border-radius: 50%
    width: 60px
    height: 60px
    background-color: black
    position: absolute
    top: 100%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 2
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1) 
    path
      transform: scale(0.6)
      transform-origin: center
      stroke: #FFF
