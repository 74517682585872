.App
  width: 100%
  height: 100%
  text-align: center
  display: flex
  overflow: hidden
  background: #080808
  &__Main
    min-width: 0
    width: 100%
    height: 100%
    position: relative
  &__More
    flex: 1
    position: absolute
    width: 100vw
    height: 100%
    max-width: 450px
    transition-duration: .3s
    overflow-x: hidden !important
    top: 0
    left: 100%
    z-index: 3
    box-shadow: none
    background-color: #1d1d1d
    > button
      position: absolute
      top: 1.5rem
      right: 1.5rem
      color: #ffffff
      background-color: #040404
      font-size: 0.75rem
      font-weight: bold
      border: 0
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1)
      padding: 0.125rem 0.75rem
      border-radius: 1rem
 
@media (max-width: 1024px)
  .App
    &__Main
      width: 100% !important
    &__More
      box-shadow: 0 0 10px rgba(0,0,0, 0.3)